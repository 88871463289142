import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {TranslateService} from './translate.service';
import {HttpClientModule} from '@angular/common/http';
import {PipeModule} from './pipe/pipe.module';
import {HTTP} from '@ionic-native/http/ngx';
import {LocationManagerService} from './location-manager.service';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {OrderService} from './order.service';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {LocalNotifications} from "@ionic-native/local-notifications/ngx";
import {Deeplinks} from "@ionic-native/deeplinks/ngx";

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('hu');
}


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    PipeModule,
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    LocationManagerService, Geolocation, Deeplinks,
    HTTP,
    OrderService,
    TranslateService,
    FirebaseX,
    Geolocation,
    LocalNotifications,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    },
    StatusBar,
    SplashScreen,
    InAppBrowser,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {
}

