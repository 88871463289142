import {
  Component,
  EventEmitter,
  Input, NgZone,
  Output,
} from '@angular/core';
import {LocationManagerService} from '../../location-manager.service';
import {Geoposition} from '@ionic-native/geolocation';
import {RestApiService} from '../../rest-api.service';
import {LocationFilter} from '../../location-filter';
import {ActivatedRoute, Router} from '@angular/router';
import {HeaderService} from './header.service';
import {HeaderFilter} from './header-filter';
import {AlertController, ModalController} from '@ionic/angular';
import {Address} from '../../api/address';
import {MmCityZipSearchComponent} from '../../mm-city-zip-search/mm-city-zip-search.component';
import {MmLocationsearchComponent} from '../../mm-locationsearch/mm-locationsearch.component';
import {MmCitySearchComponent} from "../../mm-city-search/mm-city-search.component";

declare let gaEvent: Function;

@Component({
  selector: 'app-headbar',
  templateUrl: './headbar.component.html',
  styleUrls: ['./headbar.component.scss'],
  providers: []
})
export class HeadbarComponent {

  @Input() menuAndCart: boolean = false;
  @Input() searchAndFilter: boolean;
  @Input() title: string;
  @Input() backButton: boolean;
  @Input() hasLogoOnMobile: boolean = false;
  @Input() backButtonText: string;
  @Input('nearLocation') forceNeedLocation: boolean = false;
  nearLocation: boolean = false;

  @Output() filterChange: EventEmitter<HeaderFilter> = new EventEmitter();
  @Output() backPressed = new EventEmitter();

  dailyMenu: boolean = true;

  searchForText: string;
  searchForCity: KeyboardEvent;
  filters: boolean = false;
  delivery: any = {};
  nearBy: boolean = false;

  openSearcher: boolean = false;
  locSearching: boolean = true;
  locStatus: boolean = false;
  locCurrent: Geoposition = <Geoposition>{};

  searchingLocation: boolean = false;

  locFilter: LocationFilter = {need: false, location: null};
  deliveryFilterActive: boolean = false;
  deliveryFilter: number;
  freeFilterActive: boolean = false;
  freeFilter: string[] = [];
  Addresses: Address[] = [];
  selectedCity: {};
  founds: string[] = [];
  city: string;
  openedCitySearch: boolean = false;
  onlyDeliveryFilterActive: boolean;

  constructor(private loc: LocationManagerService,
              private api: RestApiService,
              private router: Router,
              private ngZone: NgZone,
              private alertController: AlertController,
              private serv: HeaderService,
              private modalController: ModalController,
              private arouter: ActivatedRoute) {

    this.arouter.queryParams.subscribe(r => {
      this.nearLocation = this.serv.nearLocation;
      this.nearBy = this.serv.nearBy;
      this.locFilter = this.serv.location;
      this.delivery = this.serv.delivery;
      this.dailyMenu = this.serv.dailyMenu;

      this.searchForText = r.search || this.serv.search;
      if (r.type) {
        this.dailyMenu = r.type === 'menu';
      }
      if (r.location) {
        this.locFilter = {
          need: true,
          location: {lat: r.location.split(',')[0], lng: r.location.split(',')[1]}
        };
        this.nearLocation = true;
      }
      this.applyFilter();

      if (this.api.myMember.MemberToken) {
        this.api.getAddresses().subscribe(r => {
          this.Addresses = r.data.Addresses;
        });
      }
    });

    this.serv.filterOpenAction.subscribe(r => {
      switch (r) {
        case 'delivery':
          this.onDeliverClick();
          break;
        case 'free':
          this.onFreeFilterClick();
          break;
        case 'location':
          this.onNearLocationClick();
          break;
      }
    });

    this.locSearching = true;
    this.locStatus = false;
    this.locCurrent = <Geoposition>{};

    this.locStatus = loc.currentStatus;
    loc.locStatus.subscribe(r => {
      this.locStatus = r;

    });
    this.locCurrent = loc.currentPosition;

    this.locSearching = !this.locCurrent;

    loc.selectedLocation.subscribe(r => {
      this.selectedCity = r;
      this.applyFilter();
    });

    if (localStorage.getItem('cityClicked') &&
        JSON.parse(localStorage.getItem('cityClicked')).expiry > new Date().getTime()) {
      this.selectedCity = JSON.parse(localStorage.getItem('cityClicked')).value;
    } else {
      if (localStorage.getItem('profile') && JSON.parse(localStorage.getItem('profile')).MemberBillCity) {
        this.selectedCity = JSON.parse(localStorage.getItem('profile')).MemberBillCity;
      }
    }

    loc.locChange.subscribe(r => {
      this.locSearching = false;
      this.locCurrent = r;
      if (!this.locFilter.need) {
        this.locFilter.need = true;
        this.locFilter.location = {lat: this.locCurrent.coords.latitude, lng: this.locCurrent.coords.longitude};
        this.applyFilter();
      }
    });

  }

  onCitySearch(e: KeyboardEvent) {
    if (e.target['value'].length === 0) {
      this.founds = [];
      this.openedCitySearch = false;
      return;
    }

    this.searchForCity = e;
    this.selectedCity = e.target['value'];
    this.city = e.target['value'];

    if (e.target['value'].length > 1) {
      this.api.searchCity(e.target['value']).subscribe(r => {
        this.founds = r.data;
        this.openedCitySearch = this.founds.length != 0;
      });
    }
  }

  onClickRestaurantCitySearch() {
    this.api.searchRestaurantCities('').subscribe(r => {
      this.founds = r.data;

      this.openedCitySearch = this.founds.length != 0;
    });
  }

  onBlurCitySearch() {
    setTimeout(() => {
      this.founds = [];
      this.openedCitySearch = false;
    }, 200);
  }

  onRestaurantCitySearch(e: KeyboardEvent) {
    if (e.target['value'].length === 0) {
      this.founds = [];
      this.openedCitySearch = false;
      return;
    }

    this.searchForCity = e;
    this.selectedCity = e.target['value'];
    this.city = e.target['value'];

    if (e.target['value'].length >= 1) {
      this.api.searchRestaurantCities(e.target['value']).subscribe(r => {
        this.founds = r.data;
        this.openedCitySearch = this.founds.length != 0;
      });
    }
  }


  onCitySearchClick(item?: any) {
    this.founds = [];
    this.selectedCity = '';
    if (item) {
      this.city = item.RestaurantCity;
      this.selectedCity = item.RestaurantCity;
      gaEvent('citySearchFilter', item.RestaurantCity);
      this.applyFilter();
      this.openedCitySearch = false;
    }
  }

  onCitySearchClear() {
    this.city = '';
    this.selectedCity = '';
    this.founds = [];
    this.openedCitySearch = false;
    this.applyFilter();
  }

  doSearch(e?: any) {

    if (this.searchForText) {
      gaEvent('search', this.searchForText);
      this.router.navigate([], {queryParams: {search: this.searchForText}});
    } else {
      this.serv.search = '';
      this.router.navigate([], {queryParams: {}});
      this.applyFilter();
    }
    if (e) {
      const activeElement = <HTMLElement>document.activeElement;

      if (activeElement && activeElement.blur) {
        activeElement.blur();
      }

    }

  }

  navToHome() {
    this.ngZone.run(() => {
      this.nearLocation = false;
      this.dailyMenu = true;
      this.searchForText = '';
      this.filters = false;
      this.delivery = {};
      this.nearBy = false;
      this.openSearcher = false;
      this.searchingLocation = false;
      this.deliveryFilterActive = false;
      this.deliveryFilter = null;
      this.freeFilterActive = false;
      this.freeFilter = [];

      this.router.navigate(['/']).then(() => {
        this.applyFilter();
      });
    });
  }

  applyFilter() {
    this.serv.search = this.searchForText;
    this.serv.location = this.locFilter;
    this.serv.delivery = this.delivery;
    this.serv.dailyMenu = this.dailyMenu;
    this.serv.freeFilter = this.freeFilter;
    this.serv.city = this.city;

    this.serv.nearBy = this.nearBy;
    this.serv.nearLocation = this.nearLocation;
    const filter = {
      search: this.searchForText,
      location: this.locFilter,
      delivery: this.delivery,
      hasCarte: !this.dailyMenu,
      freeFilter: this.freeFilter,
      city: this.city,
      onlyDelivery: this.onlyDeliveryFilterActive,
    };
    this.filterChange.emit(filter);
  }

  onFreeFilterClick() {
    const filters = [
      // {value: 'DishSpicy', label: 'Fűszeres'},
      {value: 'DishDiabetic', label: 'Diabetikus'},
      {value: 'DishGlutenFree', label: 'Gluténmentes'},
      {value: 'DishLactoseFree', label: 'Laktóz mentes'},
      {value: 'DishVega', label: 'Vega'},
      {value: 'DishVegan', label: 'Vegán'},
      {value: 'DishPaleo', label: 'Paleo'},
    ];
    const inputs = [];
    for (let i of filters) {
      inputs.push({
        value: i.value,
        label: i.label,
        type: 'checkbox',
        checked: this.freeFilter.indexOf(i.value) > -1,
      });
    }

    this.alertController.create({
      mode: 'ios',
      cssClass: 'mm-alert',
      header: 'Mentes szűrő',
      inputs: inputs,
      buttons: [
        {
          text: 'Törlés',
          cssClass: 'secondary',
          handler: () => {
            this.freeFilterActive = false;
            this.freeFilter = [];
            this.applyFilter();
          }
        }, {
          text: 'Szűrés',
          handler: (filters) => {
            this.freeFilterActive = !!filters.length;
            this.freeFilter = filters;
            this.filters = !!filters.length;

            this.applyFilter();
          }
        }
      ]
    }).then(alert => {
      return alert.present();
    });
  }

  onDeliverClick_() {
    this.modalController.create({
      mode: 'ios',
      cssClass: 'mm-modal',
      component: MmCityZipSearchComponent,
      componentProps: {
        header: 'Keresés',
        searchLabel: 'Település kereső',
        searchPlaceholder: 'Adj meg írányítószámot vagy települést',
        selectedFound: {City: this.delivery.City},
      },
    }).then(modal => {
      modal.onDidDismiss().then((r) => {
        if (r.role == 'backdrop') {
          return;
        }
        if (r.data && r.data.ZipCode) {
          this.deliveryFilterActive = true;
          this.delivery = {
            City: r.data.City,
            Zip: r.data.ZipCodeID,
          };
          gaEvent('deliveryFilter', r.data.ZipCode);
          this.filters = true;
          this.applyFilter();
        } else {
          this.deliveryFilterActive = false;
          this.delivery = {};
          this.applyFilter();
        }
      });
      return modal.present();
    });
  }

  onDeliverClick() {
    const addresses = [];

    for (let address of this.Addresses) {
      console.log(address);
      addresses.push(
        {
          type: 'radio',
          label: address.AddressName,
          value: {
            Zip: address.ZipCodeID,
            City: address.AddressCity,
          },
          checked: address.ZipCodeID == this.delivery.Zip,
        }
      );
    }
    if (addresses.length < 1) {
      this.onDeliverClick_();
    } else {
      this.alertController.create({
        mode: 'ios',
        header: 'Kiszállítás',
        cssClass: 'mm-alert',
        inputs: addresses,
        buttons: [
          {
            text: '+ Új szállítási cím',
            cssClass: 'secondary is-highlighted is-100 is-smaller',
            handler: () => {
              this.router.navigate(['/address', 'new']);
            }
          },
          {
            text: 'Település kereső',
            role: 'dismiss',
            cssClass: 'secondary is-highlighted is-100',
            handler: () => {
              this.onDeliverClick_();
            }
          },
          {
            text: 'Törlés',
            cssClass: 'secondary',
            handler: () => {
              this.deliveryFilterActive = false;
              this.delivery = {};
              this.applyFilter();
            }
          }, {
            text: 'Szűrés',
            handler: (filters) => {
              this.deliveryFilterActive = filters != undefined;
              this.filters = filters != undefined;
              this.delivery = filters || {};
              gaEvent('deliveryFilter', filters);
              this.applyFilter();
            }
          }
        ]
      }).then(alert => {
        return alert.present();
      });
    }
  }

  onNearLocationClick() {

    this.modalController.create({
      mode: 'ios',
      cssClass: 'mm-modal',
      component: MmLocationsearchComponent,
      componentProps: {
        header: 'Címhez közel',
        searchLabel: 'Cím keresés',
        selectedFound: {Text: this.locFilter.text, location: this.locFilter.location},
      },
    }).then(modal => {
      modal.onDidDismiss().then((r) => {
        if (r.role == 'backdrop') {
          return;
        }
        if (r.data && r.data.location) {
          gaEvent('searchByAddress', r.data.Text);
          this.locFilter = {need: true, location: r.data.location, text: r.data.Text};
          this.nearLocation = true;
          this.filters = true;
          this.applyFilter();
        } else {
          this.applyGpsLocationFilter();

          this.nearLocation = false;
          this.applyFilter();
        }
      });
      return modal.present();
    });

  }

  toogleFilters() {
    this.filters = !this.filters;
    if (!this.filters) {

      this.freeFilterActive = false;
      this.freeFilter = [];
      this.deliveryFilterActive = false;
      this.delivery = {};
      this.applyGpsLocationFilter();
      this.nearLocation = false;
      this.applyFilter();
    }
  }

  applyGpsLocationFilter() {
    if (this.locStatus) {
      this.locFilter = {
        need: this.locStatus,
        location: {lat: this.locCurrent.coords.latitude, lng: this.locCurrent.coords.longitude}
      };
    } else {
      this.locFilter = {
        need: false,
        location: {lat: 0, lng: 0}
      };
    }
  }

  onDeliverFilterClick() {
    this.onlyDeliveryFilterActive = !this.onlyDeliveryFilterActive;

    this.applyFilter();
  }
}
