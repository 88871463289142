import {Injectable, NgZone} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Observable, OperatorFunction, Subject, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {HTTP} from '@ionic-native/http/ngx';
import {catchError} from 'rxjs/operators';
import {from} from 'rxjs';
import {map} from 'rxjs/operators';
import {Restaurant} from './api/restaurant';
import {ServerResponse} from './api/server-response';
import {RestaurantList} from './api/restaurant-list';
import {StaticPage} from './api/static-page';
import {Member} from './api/member';
import { v4 as uuidv4 } from 'uuid';
import {SearchAddresses} from './api/search-address';
import {MenuResult} from './api/menu';
import {FirebaseX} from '@ionic-native/firebase-x/ngx';
import {Address} from './api/address';
import {AddressList} from './api/address-list';
import {RestaurantDish} from './api/restaurant-dish';
import {OrderList} from './api/order-list';
import {NewOrder} from './api/new-order';
import {FullOrder} from './api/full-order';
import {OrderResponse} from './api/orderResponse';
import {Coupon} from './api/coupon';
import {Router} from '@angular/router';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {environment} from "../environments/environment";
import {Feedback} from "./api/feedback";
import {ToastService} from "./toast.service";

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  public canGoBack: boolean = false;
  public myProfile: Subject<Member> = new Subject();
  public myMember: Member = <Member>{};
  public apiHost = environment.apiUrl;
  public apiBase = '/v1';
  private selfUUID = null;

  public isForeground: boolean = true;

  private mobile = false;
  httpOptions = {headers: {}};

  public isVisitor: boolean = false;

  private firebaseToken = null;
  public guideSubject: Subject<any> = new Subject<any>();

  constructor(private platform: Platform,
              private http: HttpClient,
              private httpNativ: HTTP,
              private firebaseX: FirebaseX,
              private localNotifications: LocalNotifications,
              private router: Router,
              private ngZone: NgZone,
              private inapp: InAppBrowser,
              private toastService: ToastService) {

    // this.mobile = !(this.platform.is('desktop') || this.platform.is('mobileweb'));
    this.mobile = false;
    this.myProfile.subscribe(p => {
      this.myMember = p;
      if (p.MemberToken) {
        this.httpOptions.headers['X-API-KEY'] = p.MemberToken;

        if (this.firebaseToken) {
          this.sendPushToken(this.firebaseToken).subscribe((r) => {
            this.myMember.DeviceID = r.data.DeviceID;
            localStorage.setItem('profile', JSON.stringify(this.myMember));
          });
        }

      } else {
        this.httpOptions.headers = {};
      }

      localStorage.setItem('profile', JSON.stringify(p));
    });
    const oldProfile = localStorage.getItem('profile');
    if (oldProfile) {
      this.myProfile.next(JSON.parse(oldProfile));
    }
    this.selfUUID = uuidv4();
    this.platform.ready().then(() => {
      this.firebaseX.getToken().then(token => {
        this.firebaseToken = token;
        if (this.myMember.MemberToken) {
          this.sendPushToken(token).subscribe((r) => {
            this.myMember.DeviceID = r.data.DeviceID;
            localStorage.setItem('profile', JSON.stringify(this.myMember));
          });
        }
        this.firebaseX.onMessageReceived().subscribe(r => {
          if (r.show_notification == 'false') {
            r.show_notification = null;
            this.localNotifications.schedule({
              title: r.title,
              text: r.body,
              foreground: true,
              data: r
            });
            this.localNotifications.on('click').subscribe(data => {
              this.handlerPush(data.data);
            });
          } else {
            this.handlerPush(r);
          }
        });
      });
    });


  }

  public handlerPush(r) {
    this.ngZone.run(async () => {
      switch (r.action) {
        case 'review':
          this.router.navigate(['/review', r.GroupID]);
          break;
        case 'openRestaurant':
          await this.router.navigate(['/restaurant', r.param], {state: {}});
          await this.router.navigate(['/restaurant', r.param], {state: {type: r.type}});
          break;
        case 'openLink':
          this.inapp.create(r.param, '_system');
          break;
        case 'openList':
          await this.router.navigate([`/list`], {queryParams: {}});
          await this.router.navigate([`/list`], {queryParams: {location: r.param, type: r.type}});
          break;
        case 'openOrder':
          this.router.navigate(['/orders', r.param]);
          break;
      }
    });
  }

  private doRequest(method, path, data, showError = true): Observable<any> {
    let url = `${this.apiHost}${this.apiBase}${path}`;
    // console.log(method, path, data);
    // let url = this.mobile ? `${this.apiHost}${this.apiBase}${path}` : `${this.apiBase}${path}`;
    if (this.mobile) {
      this.httpNativ.setDataSerializer('json');
    }

    switch (method) {
      case 'get':
        const getParams = this.genGETparams(data);
        if (getParams) {
          url += '?' + getParams;
        }

        return (this.mobile ?
          from(this.httpNativ.get(url, {}, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.log('error', e, r);
            }

          }))
          : this.http.get(url, this.httpOptions)).pipe(catchError((e, o) => {
          return this.errorHandler(e, showError);
        }));
      case 'delete':
        return (this.mobile ?
          from(this.httpNativ.delete(url, {}, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.log('error', e, r);
            }

          }))
          : this.http.delete(url, this.httpOptions)).pipe(catchError((e, o) => {
          return this.errorHandler(e, showError);
        }));
      case 'put':
        return (this.mobile ?
          from(this.httpNativ.put(url, data, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.log('error', e, r);
            }

          }))
          : this.http.put(url, data, this.httpOptions)).pipe(catchError((e, o) => {
          return this.errorHandler(e, showError);
        }));
      case 'post':
        return (this.mobile ?
          from(this.httpNativ.post(url, data, this.httpOptions.headers)).pipe(map((r) => {
            try {
              return JSON.parse(r.data);
            } catch (e) {
              console.log('error', e, r);
            }

          }))
          : this.http.post(url, data, this.httpOptions)).pipe(catchError((e, o) => {
          return this.errorHandler(e, showError);
        }));
      default:
        console.log('Nincs ilyen method!');
    }
    return new Observable();
  }

  private errorHandler(error: any, showError: boolean) {
    if (showError) {
      if (error.status === 0) {
        this.toastService.presentToast('Nincs internetkapcsolat, kérlek ellenőrizd!').then(() => {

        });
      }
      if (error.status === 401) {
        this.myProfile.next({} as Member);
        this.toastService.presentToast('Kérjük jelentkezz be a funkció használatához!').then(() => {
        });
      }
      if (error.status === 403) {
        this.toastService.presentToast('Nincs jogod').then(() => {
        });
      }
      if (error.status === 500) {
        this.toastService.presentToast('Kiszolgáló hiba').then(() => {
        });
      }
      if (error.status === 404) {
        this.toastService.presentToast('Nem található').then(() => {
        });
      }
      if (error.status === 400) {
        this.toastService.presentToast(error.error.error.message).then(() => {
        });
      }
    }

    return throwError(error);
  }


  private genGETparams(data) {
    const ret = [];
    for (const i of Object.keys(data)) {
      if ((data[i] !== undefined) && (data[i] !== '')) {
        ret.push(i);
      }
    }
    return ret.map(key => `${key}=${encodeURIComponent(data[key])}`).join('&');
  }

  public getRestaurantList(datetime: string, Location?: string, Delivery?: string, Page?: number, PerPage?: number, Search?: string, Radius?: number, hasCarte?: boolean, FreeFilter?: string[], City?: string, OnlyDelivery?: boolean): Observable<ServerResponse<RestaurantList>> {
    return this.doRequest('get', `/restaurant/list/`, {
      'Date': datetime,
      'Location': Location,
      'Page': Page,
      'PerPage': PerPage,
      'Search[DeliveryZip]': Delivery,
      'Search[String]': Search,
      'Search[FreeFilter]': FreeFilter,
      'Search[OnlyDelivery]': OnlyDelivery,
      'Radius': Radius,
      'HasCarte': hasCarte,
      'City': City,
    });
  }

  public getRestaurantFavoriteList(datetime: string, Page: number, PerPage: number): Observable<ServerResponse<RestaurantList>> {
    return this.doRequest('get', `/restaurant/list/`, {
      'Favorite': true,
      'Date': datetime,
      'Page': Page,
      'PerPage': PerPage
    });
  }

  public postFavorite(id): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/restaurant/${id}/favorite/`, {});
  }

  public loadRestaurant(id, datetime, Location?: string): Observable<ServerResponse<Restaurant>> {
    return this.doRequest('get', `/restaurant/${id}/`, {'Date': datetime, 'Location': Location});
  }

  public getPage(link, showError = true, city?: string): Observable<ServerResponse<StaticPage>> {
    return this.doRequest('get', `/page/${link}/`, {'city': city}, showError);
  }

  public getPopups(city?: string, returningUser = false): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/popup/${city}/`, {'returningUser': returningUser});
  }

  public login(username, password, _recaptcha): Observable<ServerResponse<Member>> {
    // this.myProfile.next(<Member>{});
    return this.doRequest('post', '/login/', {
      MemberEmail: username,
      MemberPassword: password,
      _recaptcha
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile.next(r.data);
      } else {
        this.myProfile.next(<Member>{});
      }

      return r;
    }), catchError((e, o) => {
      this.myProfile.next(<Member>{});
      return throwError(e);
    }));
  }

  public loginFacebook(AccessToken: string): Observable<ServerResponse<Member>> {
    // this.myProfile.next(<Member>{});
    return this.doRequest('post', '/login/facebook/', {
      AccessToken
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile.next(r.data);
      } else {
        this.myProfile.next(<Member>{});
        this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!');
      }

      return r;
    }), catchError((e, o) => {
      this.myProfile.next(<Member>{});
      this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!').then();
      return throwError(e);
    }));
  }

  public loginGoogle(AccessToken: string): Observable<ServerResponse<Member>> {
    // this.myProfile.next(<Member>{});
    return this.doRequest('post', '/login/google/', {
      AccessToken
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile.next(r.data);
      } else {
        this.myProfile.next(<Member>{});
        this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!');
      }

      return r;
    }), catchError((e, o) => {
      this.myProfile.next(<Member>{});
      this.toastService.presentToast('Helytelen bejelentkezési adatokat adott meg!').then();
      return throwError(e);
    }));
  }


  public resetPassword(resetPasswordToke: string, newPassword: string, _recaptcha: string): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/reset-password/${resetPasswordToke}/`, {newPassword, _recaptcha});
  }

  public register(firstName: string, lastName: string, email: string, password: string, terms: boolean, _recaptcha: string): Observable<ServerResponse<Member>> {
    this.myProfile.next(<Member>{});
    return this.doRequest('post', '/register/', {
      MemberEmail: email,
      MemberPassword: password,
      MemberFirstName: firstName,
      MemberLastName: lastName,
      Terms: terms,
      _recaptcha
    }).pipe(map(r => {
      if (r.success) {
        this.myProfile.next(r.data);
      } else {
        this.myProfile.next(<Member>{});
      }
      return r;
    }));
  }

  public logout(): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/logout/', {DeviceID: this.myMember.DeviceID}).pipe(map(r => {
      this.myProfile.next(<Member>{});
      return r;
    }));
  }

  public forget(email: string, _recaptcha: string): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/forgot/', {MemberEmail: email, _recaptcha});
  }

  public sendPushToken(token): Observable<ServerResponse<any>> {
    const deviceId = this.myMember.DeviceID;
    this.myMember.FirebaseToken = token;
    let platform = null;
    if (this.platform.is('android')) {
      platform = 'android';
    } else if (this.platform.is('ios')) {
      platform = 'ios';
    }

    return this.doRequest('post', '/profile/device/', {
      DeviceToken: token,
      DeviceID: deviceId,
      DevicePlatform: platform
    });
  }

  public searchAddress(text: string): Observable<ServerResponse<SearchAddresses>> {
    return this.doRequest('get', '/search/autocomplete/', {Input: text, Token: this.selfUUID});
  }

  public loadMenu(key: string): Observable<ServerResponse<MenuResult>> {
    return this.doRequest('get', `/menu/${key}/`, {});
  }

  public getAddresses(): Observable<ServerResponse<AddressList>> {
    return this.doRequest('get', '/profile/addresses/', {});
  }

  public newAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('post', '/profile/address/', model);
  }

  public updateAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('put', `/profile/address/${model.AddressID}/`, model);
  }

  public deleteAddress(model: Address): Observable<ServerResponse<any>> {
    return this.doRequest('delete', `/profile/address/${model.AddressID}/`, {});
  }

  public loadAddress(AddressID: number): Observable<ServerResponse<Address>> {
    return this.doRequest('get', `/profile/address/${AddressID}/`, {});
  }

  public updateProfile(member: Member): Observable<ServerResponse<MenuResult>> {
    return this.doRequest('post', `/profile/`, member);
  }

  public sendAdsTrackingEvent(url): Observable<ServerResponse<any>> {
    return this.doRequest('get', url, {});
  }

  /* ------------------------- NEW APIS -------------------------------*/

  public getRestaurantDish(DishID): Observable<ServerResponse<{ Dish: RestaurantDish, Restaurant: Restaurant }>> {
    return this.doRequest('get', `/restaurant/dish/${DishID}/`, {});
  }

  public getOrdersList(Page?: number, PerPage?: number): Observable<ServerResponse<OrderList>> {
    return this.doRequest('get', `/orders/`, {Page, PerPage});
  }

  public newOrder(model: NewOrder): Observable<ServerResponse<OrderResponse>> {
    return this.doRequest('post', `/order/`, model);
  }

  public loadOrder(OrderID: number): Observable<ServerResponse<FullOrder>> {
    return this.doRequest('get', `/order/${OrderID}/`, {});
  }

  public postReview(id, Review: number): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/ordergroup/${id}/review/`, {Review});
  }

  public checkCoupon(CouponCode: string): Observable<ServerResponse<Coupon>> {
    return this.doRequest('get', `/coupon/`, {CouponCode});
  }

  public getOrdergroupReview(id: number): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/ordergroup/${id}/review/`, {});
  }

  public searchZipCode(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/zip/?q=${q}`, {});
  }

  public searchCity(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/city/?q=${q}`, {});
  }

  public searchRestaurantCities(q: string): Observable<ServerResponse<any>> {
    return this.doRequest('get', `/search/restaurantcity/?q=${q}`, {});
  }

  public sendVote(restaurandId: number): Observable<ServerResponse<any>> {
    return this.doRequest('post', `/vote/`, {
      restaurantId: restaurandId,
      token: JSON.parse(localStorage.getItem('uid')).value
    });
  }

  public deleteAccount(): Observable<ServerResponse<any>> {
    return this.doRequest('delete', `/profile/`, {});
  }

  public sendFeedback(feedbackForm: Feedback): Observable<ServerResponse<any>> {
      return this.doRequest('post', `/feedback/`, feedbackForm);
  }
}
