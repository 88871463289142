import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RestApiService} from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoginGuard implements CanActivate {

  constructor(private router: Router, private api: RestApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.api.myMember.MemberToken) {
      this.router.navigate(['/']);
      return false;
    }
    if (this.api.isVisitor) {
      return false;
    }
    return true;
  }

}
