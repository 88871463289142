import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {RestApiService} from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class FirstLoginGuard implements CanActivate {
  firstTime: boolean = true;

  constructor(private router: Router, private api: RestApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.firstTime && !this.api.myMember.MemberToken) {
      this.router.navigate(['/login']);
      this.firstTime = false;
      return false;
    }
    return true;
  }


}
